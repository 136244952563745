<template>
  <div class="main-box" v-if="info">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/order/index' }">订单</el-breadcrumb-item>
      <el-breadcrumb-item>退款详情</el-breadcrumb-item>
    </el-breadcrumb>
    <el-row :gutter="60" class="mg-tp-20">
      <el-col :span="12" style="border-right: 1px solid #f5f5f5">
        <el-divider content-position="left">维权信息</el-divider>
        <el-table
            :data="info.goods"
            ref="rfTable"
            border
            style="width: 100%"
            :span-method="objectSpanMethod"
            size="mini">
          <el-table-column
              prop="goods"
              label="商品">
            <template slot-scope="scope">
              <div class="goods-box line">
                <div class="item">
                  <img :src="scope.row.goods_img" class="img small" />
                  <div>
                    <div class="name">{{scope.row.goods_name}}</div>
                    <div class="spec">{{scope.row.specs_name}}</div>
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
              prop="quantity"
              label="数量"
              align="center"
              width="80">
          </el-table-column>
          <el-table-column
              prop="price"
              label="单价(元)"
              align="center"
              width="80">
          </el-table-column>
          <el-table-column
              prop="pay_price"
              label="商品总价(元)"
              align="center"
              width="100">
            <template slot-scope="scope">
              {{ info.goods_amount }} {{scope.row.id}}
            </template>
          </el-table-column>
        </el-table>
        <el-divider content-position="left">订单详情</el-divider>
        <el-form size="small" label-position="left">
          <el-form-item label="订单号：" :label-width="formLabelWidth">
            {{ info.order_sn }}
          </el-form-item>
          <el-form-item label="下单时间：" :label-width="formLabelWidth">
            {{ info.create_time }}
          </el-form-item>
          <el-form-item label="状态：" :label-width="formLabelWidth">
            {{ info.status_str }}
          </el-form-item>
          <el-form-item label="支付状态：" :label-width="formLabelWidth">
            {{ info.pay_status==1?'已支付':'未支付' }}
          </el-form-item>
          <el-form-item label="取消时间：" :label-width="formLabelWidth" v-if="info.cancel_time">
            {{ info.cancel_time }}
          </el-form-item>
          <el-form-item label="支付时间：" :label-width="formLabelWidth" v-if="info.pay_time">
            {{ info.pay_time }}
          </el-form-item>
          <el-form-item label="交易单号：" :label-width="formLabelWidth" v-if="info.payment_sn">
            {{ info.payment_sn }}
          </el-form-item>
          <el-form-item label="微信单号：" :label-width="formLabelWidth" v-if="info.trade_sn">
            {{ info.trade_sn }}
          </el-form-item>
          <el-form-item label="发货时间：" :label-width="formLabelWidth" v-if="info.shipments_time">
            {{ info.shipments_time }}
          </el-form-item>
          <el-form-item label="收货时间：" :label-width="formLabelWidth" v-if="info.delivery_time">
            {{ info.delivery_time }}
          </el-form-item>
          <el-form-item label="商品总价：" :label-width="formLabelWidth">
            ￥{{ info.goods_amount }}
          </el-form-item>
        </el-form>
        <el-form size="small" label-position="left" v-for="(item,index) in info.ump" :key="index">
          <el-form-item :label="item.ump_type" :label-width="formLabelWidth">
            -￥{{ item.amount }}
          </el-form-item>
        </el-form>
        <el-form size="small" label-position="left">
          <el-form-item label="实付金额：" :label-width="formLabelWidth">
            ￥{{ info.amount }}
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="12">
        <el-divider content-position="left">退款记录</el-divider>
        <el-form size="small" label-position="left">
          <el-form-item v-if="titleBox.title">
            <i class="el-icon-alarm-clock"></i><span class="mg-lf-5">{{ titleBox.title }}</span><div class="c999">{{ titleBox.desc }}</div>
          </el-form-item>
          <el-form-item label="">
            <span v-if="refundInfo.status==4" class="font12">您还可以：<el-button type="text" @click="toRefund(1)">同意退款给买家</el-button></span>
            <el-button type="warning" size="mini" @click="toRefund(1)" v-if="titleBox.agree_btn">{{ titleBox.agree_btn }}</el-button>
            <el-button type="primary" size="mini" @click="toRefund(2)" v-if="titleBox.refuse_btn">{{ titleBox.refuse_btn }}</el-button>
            <span v-if="refundInfo.status<10">
              <el-popconfirm v-if="refundInfo.is_can_refuse==1"
                  class="mg-lf-10"
                  title="确定关闭当前订单吗？"
                  @confirm="closeRefund()">
                <el-button type="danger" size="mini" slot="reference">结束维权</el-button>
              </el-popconfirm>
              <span class="mg-lf-10" v-else>
                <el-button type="info" plain size="mini" disabled>结束维权</el-button>
              </span>
              <div class="c999">商家完成协商处理且用户7天内无操作后，商家可手动结束维权流程</div>
            </span>
          </el-form-item>
          <div class="mg-bt-20"></div>
          <el-timeline>
            <el-timeline-item type="primary" placement="bottom" v-for="(item,index) in refundInfo.log" :key="index">
              <el-card>
                <div class="nav">
                  <span class="name">{{ item.who }}</span>
                  <span class="time">{{ item.create_time }}</span>
                </div>
                <div class="desc">{{ item.desc }}</div>
                <div class="list">
                  <div class="item" v-for="(child,cindex) in item.content" :key="cindex">
                    <div class="label">{{ child.title }}：</div>
                    <div class="value" v-if="child.title=='退款凭证'">
                      <span :images="child.desc">
                        <img v-for="(item,idx) in child.desc" :src="item" :key="idx" width="60" height="60" class="mg-rt-5">
                      </span>
                    </div>
                    <div class="value" v-else>{{ child.desc }}</div>
                  </div>
                </div>
              </el-card>
            </el-timeline-item>
          </el-timeline>
        </el-form>
      </el-col>
    </el-row>
    <el-dialog title="维权处理" :visible.sync="dialogVisible" width="600px">
      <el-form size="small" label-position="left" label-width="80px">
        <el-form-item label="处理方式:">
          退款
        </el-form-item>
        <el-form-item label="退款金额:">
          ￥{{ refundInfo.amount }}
        </el-form-item>
<!--        <el-form-item label="退款余额:" v-if="refundInfo.balance>0">-->
<!--          ￥{{ refundInfo.balance }}-->
<!--        </el-form-item>-->
        <el-form-item label="退款积分:" v-if="refundInfo.integral>0">
          {{ refundInfo.integral }}
        </el-form-item>
        <el-form-item label="拒绝理由:" v-if="agree_type==2">
          <el-input type="textarea" :rows="4" placeholder="请输入拒绝理由" v-model="reason"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取消</el-button>
        <el-button type="danger" @click="sureRefund()" size="small" v-if="agree_type==1">同意退款</el-button>
        <el-button type="danger" @click="sureRefund()" size="small" v-if="agree_type==2">拒绝退款</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>

export default {
  components: {
  },
  data() {
    return {
      id: 0,
      info: false,
      refundInfo: false,
      formLabelWidth: '90px',
      dialogVisible: false,
      titleBox: {
        title: '',
        desc: '',
        agree_btn: '',
        refuse_btn: ''
      },
      agree_type: 0,
      reason: '',
      address: ''
    };
  },
  created() {
    if (this.$route.params.id) {
      this.id = parseInt(this.$route.params.id)
      this.getOrderInfo()
      this.getRefundInfo()
    }
  },
  mounted() {
  },
  computed: {},
  methods: {
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex >= this.info.goods.length+2) {
        return {
          rowspan: 3,
          colspan: 1
        };
      }
    },
    getOrderInfo() {
      this.$api.order.orderDetail({id:this.id}, res => {
        if(res.errcode==0) {
          this.info = res.data
        } else {
          this.fail(res.errmsg)
        }
      })
    },
    getRefundInfo() {
      this.titleBox.title = ''
      this.titleBox.desc = ''
      this.titleBox.refuse_btn = ''
      this.titleBox.agree_btn = ''
      this.showLoading()
      this.$api.order.refundDetail({id:this.id}, res => {
        this.hideLoading()
        if(res.errcode==0) {
          this.refundInfo = res.data
          if(this.refundInfo.status==1 || this.refundInfo.status==2) { //申请退款中，再次申请退款中
            this.titleBox.title = '等待商家处理退款申请'
            this.titleBox.desc = '收到买家 '+(this.refundInfo.status==2?'再次':'') +'退款申请，请及时处理'
            this.titleBox.agree_btn = this.refundInfo.refund_type==1?'同意买家退款':'同意退货并发送退货地址'
            this.titleBox.refuse_btn = '拒绝退款'
          } else if(this.refundInfo.status==3) { //商家同意退款，等待发货
            this.titleBox.title = '商家同意退款，等待买家发货'
            this.titleBox.desc = '发货地址已发给买家，等待买家发货'
          } else if(this.refundInfo.status==4) { //商家拒绝退款
            this.titleBox.title = '商家拒绝退款'
            this.titleBox.desc = '您已拒绝本次退款申请，买家修改退款申请后，需要您重新处理'
          } else if(this.refundInfo.status==5) { //买家已发货，等待卖家收货
            this.titleBox.title = '买家已发货，等待卖家确认'
            this.titleBox.desc = '买家已发货，请注意查收'
            this.titleBox.agree_btn = '确认收货，同意退款'
            this.titleBox.refuse_btn = '拒绝退款'
          } else if(this.refundInfo.status==12) { //退款完成
            this.titleBox.title = '退款完成'
            this.titleBox.desc = '退款已完成'
          } else if(this.refundInfo.status==10 || this.refundInfo.status==11) { //取消
            this.titleBox.title = '退款由'+(this.refundInfo.status==10?'买家':'卖家')+'取消'
            this.titleBox.desc = '退款已关闭'
          }
        } else {
          this.fail(res.errmsg)
        }
      })
    },
    toRefund(type) {
      this.agree_type = type
      this.dialogVisible = true
    },
    sureRefund() {
      var params = {
        order_id: this.id,
        type: this.agree_type,
        reason: this.reason,
        address: this.address
      }
      this.$api.order.refundAgree(params, res => {
        if(res.errcode==0) {
          this.success(res.errmsg)
          this.dialogVisible = false
          this.getOrderInfo()
          this.getRefundInfo()
        } else {
          this.fail(res.errmsg)
        }
      })
    },
    sureDelivery() {
      var params = {
        order_id: this.id
      }
      this.$api.order.refundSure(params, res => {
        if(res.errcode==0) {
          this.success(res.errmsg)
          this.dialogVisible = false
          this.getOrderInfo()
          this.getRefundInfo()
        } else {
          this.fail(res.errmsg)
        }
      })
    },
    closeRefund() {
      this.$api.order.refundClose({order_id:this.id}, res => {
        if(res.errcode==0) {
          this.success(res.errmsg)
          this.getOrderInfo()
          this.getRefundInfo()
        } else {
          this.fail(res.errmsg)
        }
      })
    }
  }
};
</script>
<style scoped>
.gdtable tr {
  background-color: unset;
  border-bottom: 1px solid #f5f5f5
}
.gdtable tr:last-child {
  border-bottom: none !important;
}
.gdtable td {
  padding: 5px 0;
}
.el-form-item {
  margin-bottom: 5px;
}
/* 移除table组件hover背景色 */
.el-table__row:hover> td{
  background: #fff !important;
}
.nav {
  border-bottom: 1px dashed #e5e5e5;
  width: 60%;
  font-size: 14px;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.nav .name {
  margin-right: 60px;
}
.desc {
  margin: 5px 0 10px 0;
  font-size: 14px;
}
.list .item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.list .item .label {
  width: 90px;
  color: #999;
  flex-shrink: 0;
}
</style>
